@import '@andes/normalize/index';
@import '@andes/typography/index';

html,
body,
main {
  height: 100%;
}

.section {
  width: 100%;
  height: 100%;
  flex: 100% 0;
  display: flex;

  &.col-50 {
    flex: 50% 0;
    @media screen and (max-width: $breakpoint-desktop) {
      height: 50%;
      flex: 100% 0;
    }
  }

  &.section-center--card {
    justify-content: center;
    align-items: center;

    .logo-img {
      width: 260px;
    }
  }

  &.section-info--card {
    .card {
      width: 100%;

      .card-content {
        height: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          margin: 0;
        }
      }

      .card-header {
        .logo-img {
          width: 260px;
        }
      }
    }
  }
}

.title {
  p {
    font-weight: 300 !important;
  }
}

.logo {
  padding-top: $andes-spacing-32;
}

.subtitle {
  font-size: $font-size-14;
  padding: $andes-spacing-64 0;
}

.palette-gray--main {
  background-color: $andes-gray-040-solid;
}

.palette-white--main {
  background-color: $andes-white;
}

.text-align-center {
  text-align: center;
}

.card {
  width: 100%;
  height: auto;
  padding: $andes-spacing-8;
  display: flex;
  flex-direction: column;
  gap: $andes-spacing-8;

  .card-footer {
    display: flex;
    flex-wrap: wrap;
    gap: $andes-spacing-8;
    justify-content: center;

    a:not(:hover) {
      text-decoration: none;
    }
  }

  .card-content,
  .card-header {
    display: block;
    width: 100%;
    height: auto;
  }

  .action-link {
    display: flex;
    align-items: center;

    &:first-child {
      border-right: 1px solid rgb(103 103 103 / 50%);
      padding-right: $andes-spacing-8;
    }

    .action-link-icon {
      width: 28px;
      height: auto;
    }
  }
}

.homes-tux {
  font-family: $font-family-primary;
  font-size: $font-size-16;
  font-weight: $font-weight-light;
  color: #666;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.flag {
  padding-left: $andes-spacing-8;
  display: flex;
  gap: $andes-spacing-8;

  &::before {
    content: '';
    width: 28px;
    height: 20px;
    position: relative;
    background-image: url('https://static.mlstatic.com/org-img/homesnw/img/flags@2x.png?v=3.0');
    background-size: 28px;
  }

  &.flag-colombia {
    &::before {
      background-position-y: -$andes-spacing-40;
    }
  }

  &.flag-panama {
    &::before {
      background-position-y: -160px;
    }
  }

  &.flag-venezuela {
    &::before {
      background-position-y: 160px;
    }
  }
}
